<template>
  <div id="app"  class="wrapper">
    <div class="box">
      <h3 class="BikeName white--text"> LATEST VIDEOS </h3>
      <carousel-3d :controls-visible="true" class="custom-carousel">
        <slide v-for="(slide, i) in videos" :index="i" :key="i"  class="custom-slide">
          <iframe
          :src="`https://www.youtube.com/embed/${slide.src}`"
          frameborder="0"
          allowfullscreen
          target="_blank"
          width="100%"
          height="100%"
          class="onHover"
        ></iframe>
        </slide>
      </carousel-3d>
    </div>
  </div>
</template>

<script>

import { Carousel3d, Slide  } from 'vue-carousel-3d';
export default {
  name: 'App',
  components: {
    Carousel3d,
    Slide,
  },
  data () {
    return {
      playerReady: false,
      player: null,
      slides: 12,
      slideCount: 10,
      videos: [
        { src: "mikxXFirSPY?t" },
        { src: "mikxXFirSPY?t=6" },
        { src: "7CQVGlbY8H0?t=10" },
        { src: "ytCk9zcOsj4" },
        { src: "mikxXFirSPY?t" },
        { src: "mikxXFirSPY?t=6" },
        { src: "7CQVGlbY8H0?t=10" },
        { src: "ytCk9zcOsj4" },
        { src: "ytCk9zcOsj4" },
        { src: "ytCk9zcOsj4" },
        { src: "mikxXFirSPY?t=6" },
        { src: "7CQVGlbY8H0?t=10" },
        { src: "ytCk9zcOsj4" },
        { src: "mikxXFirSPY?t" }
      ],
    }
  },
  mounted() {
    this.loadYouTubePlayerAPI();
  },
  methods: {
   
    loadYouTubePlayerAPI() {
      const tag = document.createElement('script');
      tag.src = 'https://www.youtube.com/iframe_api';
      const firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      window.onYouTubeIframeAPIReady = this.initializePlayer;
    },
    initializePlayer() {
      this.player = new window.YT.Player('youtube-player', {
        videoId: "https://www.youtube.com/embed/mikxXFirSPY?t",
        events: {
          onReady: this.onPlayerReady,
        },
      });
    },
    playVideo() {
      if (this.playerReady) {
        this.player.playVideo();
      }
    },
    
    addSlide () {
      this.slideCount++
    },
    removeSlide () {
      if (this.slideCount > 1) {
        this.slideCount--
      }
    },
  }
}
</script>

<style lang="scss">
.wrapper{
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(250, 189, 189, 0.5);
  cursor: pointer;
  z-index: 1;
}

.next[data-v-05517ad0], .prev[data-v-05517ad0]{
  color: white !important;
}

</style>