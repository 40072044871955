<template>
  <div>
 
    <v-container class="collectionBg">
      <v-row>
        <v-col cols="12">
          <v-sheet height="130" color="grey lighten-3">
            <span>
              <img src="../../assets/images/ads.png" width="100%" height="100%" alt="ad" />
            </span>
          </v-sheet>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col cols="12" class="d-flex justify-center" >
          <v-sheet 
          width="90%"
          height="130"
          color="grey lighten-3">
            <span>
             <img src="" alt="ad">
            </span>
          </v-sheet>
        </v-col>
      </v-row> -->
      <v-row>
        <v-col cols="12" class="text-center">
          <h2 class="trendingHeading py-3">All BIKES COLLECTION</h2>
        </v-col>
      </v-row>
      <v-row  class="d-flex  justify-center">
        <v-col cols="6" md="4" class="d-flex  justify-center">
          <v-card
            elevation="0"
            outlined
            width="300"
            class="CustomCardShadow ma-4"
          >
            <v-img
              class="white--text align-end"
              height="200px"
              src="../../assets/images/cards/cardBike7.jpeg"
            >
            </v-img>
            <v-divider></v-divider>
            <v-card-subtitle class="pb-0 pt-5 text-center">
              <a
                href=""
                class="text-decoration-none black--text text-center subtitle-1"
              >
                TVS Raider 125</a
              >
              <div class="">
                <a href="" class=" text-decoration-none grey--text title"
                >RS. 12000</a
              >
              </div>
            </v-card-subtitle>

            <v-card-text class="text--primary pt-5 CardText  text-center" >
              
              <div class="d-flex align-items-center justify-center">
                <div class="section">
                  <h3 class="subtitle-2">Power</h3>
                  <p>225 cc</p>
                </div>
                <span class="divider display-5">|</span>
                <div class="section">
                  <h3 class="subtitle-2">Speed</h3>
                  <p>250 km/h</p>
                </div>
                <span class="divider">|</span>
                <div class="section">
                  <h3 class="subtitle-2">Tank</h3>
                  <p>5 liters</p>
                </div>
              </div>

            </v-card-text>
          </v-card>
        </v-col>
       
        <v-col cols="6" md="4" class="d-flex  justify-center">
          <v-card
            elevation="0"
            outlined
            width="300"
            class="CustomCardShadow ma-4"
          >
            <v-img
              class="white--text align-end"
              height="200px"
              src="../../assets/images/cards/cardBike7.jpeg"
            >
            </v-img>
            <v-divider></v-divider>
            <v-card-subtitle class="pb-0 pt-5 text-center">
              <a
                href=""
                class="text-decoration-none black--text text-center subtitle-1"
              >
                TVS Raider 125</a
              >
              <div class="">
                <a href="" class=" text-decoration-none grey--text title"
                >RS. 12000</a
              >
              </div>
            </v-card-subtitle>

            <v-card-text class="text--primary pt-5 CardText  text-center" >
              
              <div class="d-flex align-items-center justify-center">
                <div class="section">
                  <h3 class="subtitle-2">Power</h3>
                  <p>225 cc</p>
                </div>
                <span class="divider display-5">|</span>
                <div class="section">
                  <h3 class="subtitle-2">Speed</h3>
                  <p>250 km/h</p>
                </div>
                <span class="divider">|</span>
                <div class="section">
                  <h3 class="subtitle-2">Tank</h3>
                  <p>5 liters</p>
                </div>
              </div>

            </v-card-text>
          </v-card>
        </v-col>
       
        <v-col cols="6" md="4" class="d-flex  justify-center">
          <v-card
            elevation="0"
            outlined
            width="300"
            class="CustomCardShadow ma-4"
          >
            <v-img
              class="white--text align-end"
              height="200px"
              src="../../assets/images/cards/cardBike7.jpeg"
            >
            </v-img>
            <v-divider></v-divider>
            <v-card-subtitle class="pb-0 pt-5 text-center">
              <a
                href=""
                class="text-decoration-none black--text text-center subtitle-1"
              >
                TVS Raider 125</a
              >
              <div class="">
                <a href="" class=" text-decoration-none grey--text title"
                >RS. 12000</a
              >
              </div>
            </v-card-subtitle>

            <v-card-text class="text--primary pt-5 CardText  text-center" >
              
              <div class="d-flex align-items-center justify-center">
                <div class="section">
                  <h3 class="subtitle-2">Power</h3>
                  <p>225 cc</p>
                </div>
                <span class="divider display-5">|</span>
                <div class="section">
                  <h3 class="subtitle-2">Speed</h3>
                  <p>250 km/h</p>
                </div>
                <span class="divider">|</span>
                <div class="section">
                  <h3 class="subtitle-2">Tank</h3>
                  <p>5 liters</p>
                </div>
              </div>

            </v-card-text>
          </v-card>
        </v-col>
       
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="6" md="4" class="d-flex  justify-center">
          <v-card
            elevation="0"
            outlined
            width="300"
            class="CustomCardShadow ma-4"
          >
            <v-img
              class="white--text align-end"
              height="200px"
              src="../../assets/images/bike2.png"
            >
            </v-img>
            <v-divider></v-divider>

            <v-card-subtitle class="pb-0 pt-5 text-center">
              <a
                href=""
                class="text-decoration-none black--text text-center subtitle-1"
              >
                TVS Raider 125</a
              >
              <div class="">
                <a href="" class=" text-decoration-none grey--text title"
                >RS. 12000</a
              >
              </div>
            </v-card-subtitle>

            <v-card-text class="text--primary pt-5 CardText  text-center" >
              
              <div class="d-flex align-items-center justify-center">
                <div class="section">
                  <h3 class="subtitle-2">Power</h3>
                  <p>225 cc</p>
                </div>
                <span class="divider display-5">|</span>
                <div class="section">
                  <h3 class="subtitle-2">Speed</h3>
                  <p>250 km/h</p>
                </div>
                <span class="divider">|</span>
                <div class="section">
                  <h3 class="subtitle-2">Tank</h3>
                  <p>5 liters</p>
                </div>
              </div>

            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6" md="4" class="d-flex  justify-center">
          <v-card
            elevation="0"
            outlined
            width="300"
            class="CustomCardShadow ma-4"
          >
            <v-img
              class="white--text align-end"
              height="200px"
              src="../../assets/images/bike3.png"
            >
            </v-img>
            <v-divider></v-divider>
            <v-card-subtitle class="pb-0 pt-5 text-center">
              <a
                href=""
                class="text-decoration-none black--text text-center subtitle-1"
              >
                TVS Raider 125</a
              >
              <div class="">
                <a href="" class=" text-decoration-none grey--text title"
                >RS. 12000</a
              >
              </div>
            </v-card-subtitle>

            <v-card-text class="text--primary pt-5 CardText  text-center" >
              
              <div class="d-flex align-items-center justify-center">
                <div class="section">
                  <h3 class="subtitle-2">Power</h3>
                  <p>225 cc</p>
                </div>
                <span class="divider display-5">|</span>
                <div class="section">
                  <h3 class="subtitle-2">Speed</h3>
                  <p>250 km/h</p>
                </div>
                <span class="divider">|</span>
                <div class="section">
                  <h3 class="subtitle-2">Tank</h3>
                  <p>5 liters</p>
                </div>
              </div>

            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6" md="4" class="d-flex  justify-center">
          <v-card
            elevation="0"
            outlined
            width="300"
            class="CustomCardShadow ma-4"
          >
            <v-img
              class="white--text align-end"
              height="200px"
              src="../../assets/images/bike4.png"
            >
            </v-img>
            <v-divider></v-divider>
            <v-card-subtitle class="pb-0 pt-5 text-center">
              <a
                href=""
                class="text-decoration-none black--text text-center subtitle-1"
              >
                TVS Raider 125</a
              >
              <div class="">
                <a href="" class=" text-decoration-none grey--text title"
                >RS. 12000</a
              >
              </div>
            </v-card-subtitle>

            <v-card-text class="text--primary pt-5 CardText  text-center" >
              
              <div class="d-flex align-items-center justify-center">
                <div class="section">
                  <h3 class="subtitle-2">Power</h3>
                  <p>225 cc</p>
                </div>
                <span class="divider display-5">|</span>
                <div class="section">
                  <h3 class="subtitle-2">Speed</h3>
                  <p>250 km/h</p>
                </div>
                <span class="divider">|</span>
                <div class="section">
                  <h3 class="subtitle-2">Tank</h3>
                  <p>5 liters</p>
                </div>
              </div>

            </v-card-text>
          </v-card>
        </v-col>

      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="6" md="4" class="d-flex  justify-center">
          <v-card
            elevation="0"
            outlined
            width="300"
            class="CustomCardShadow ma-4"
          >
            <v-img
              class="white--text align-end"
              height="200px"
              src="../../assets/images/cards/cardBike7.jpeg"
            >
            </v-img>
            <v-divider></v-divider>
            <v-card-subtitle class="pb-0 pt-5 text-center">
              <a
                href=""
                class="text-decoration-none black--text text-center subtitle-1"
              >
                TVS Raider 125</a
              >
              <div class="">
                <a href="" class=" text-decoration-none grey--text title"
                >RS. 12000</a
              >
              </div>
            </v-card-subtitle>

            <v-card-text class="text--primary pt-5 CardText  text-center" >
              
              <div class="d-flex align-items-center justify-center">
                <div class="section">
                  <h3 class="subtitle-2">Power</h3>
                  <p>225 cc</p>
                </div>
                <span class="divider display-5">|</span>
                <div class="section">
                  <h3 class="subtitle-2">Speed</h3>
                  <p>250 km/h</p>
                </div>
                <span class="divider">|</span>
                <div class="section">
                  <h3 class="subtitle-2">Tank</h3>
                  <p>5 liters</p>
                </div>
              </div>

            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6" md="4" class="d-flex  justify-center">
          <v-card
            elevation="0"
            outlined
            width="300"
            class="CustomCardShadow ma-4"
          >
            <v-img
              class="white--text align-end"
              height="200px"
              src="../../assets/images/cards/cardBike7.jpeg"
            >
            </v-img>
            <v-divider></v-divider>
            <v-card-subtitle class="pb-0 pt-5 text-center">
              <a
                href=""
                class="text-decoration-none black--text text-center subtitle-1"
              >
                TVS Raider 125</a
              >
              <div class="">
                <a href="" class=" text-decoration-none grey--text title"
                >RS. 12000</a
              >
              </div>
            </v-card-subtitle>

            <v-card-text class="text--primary pt-5 CardText  text-center" >
              
              <div class="d-flex align-items-center justify-center">
                <div class="section">
                  <h3 class="subtitle-2">Power</h3>
                  <p>225 cc</p>
                </div>
                <span class="divider display-5">|</span>
                <div class="section">
                  <h3 class="subtitle-2">Speed</h3>
                  <p>250 km/h</p>
                </div>
                <span class="divider">|</span>
                <div class="section">
                  <h3 class="subtitle-2">Tank</h3>
                  <p>5 liters</p>
                </div>
              </div>

            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6" md="4" class="d-flex  justify-center">
          <v-card
            elevation="0"
            outlined
            width="300"
            class="CustomCardShadow ma-4"
          >
            <v-img
              class="white--text align-end"
              height="200px"
              src="../../assets/images/cards/cardBike7.jpeg"
            >
            </v-img>
            <v-divider></v-divider>
            <v-card-subtitle class="pb-0 pt-5 text-center">
              <a
                href=""
                class="text-decoration-none black--text text-center subtitle-1"
              >
                TVS Raider 125</a
              >
              <div class="">
                <a href="" class=" text-decoration-none grey--text title"
                >RS. 12000</a
              >
              </div>
            </v-card-subtitle>

            <v-card-text class="text--primary pt-5 CardText  text-center" >
              
              <div class="d-flex align-items-center justify-center">
                <div class="section">
                  <h3 class="subtitle-2">Power</h3>
                  <p>225 cc</p>
                </div>
                <span class="divider display-5">|</span>
                <div class="section">
                  <h3 class="subtitle-2">Speed</h3>
                  <p>250 km/h</p>
                </div>
                <span class="divider">|</span>
                <div class="section">
                  <h3 class="subtitle-2">Tank</h3>
                  <p>5 liters</p>
                </div>
              </div>

            </v-card-text>
          </v-card>
        </v-col>

      </v-row>
    </v-container>

    <!-- Bikes collection -->
    <v-container fluid class="collectionBg">
      <v-row>
        <v-col cols="12" class="text-center">
          <h2 class="trendingHeading py-3">TRENDING BIKES COLLECTION</h2>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="3">
          <template>
            <v-card to="/BikesDetails" tile width="400">
              <v-img
                class="white--text align-end"
                height="250px"
                src="../../assets/images/bike1.png"
              >
              </v-img>

              <v-card-actions class="black white--text pa-0 customCardShadow">
                <v-btn color="white" text> More... </v-btn>

                <v-spacer></v-spacer>
                <div class="customCardBg pa-0 ma-0">
                  <img
                    src="../../assets/images/card_bg.png"
                    width="60px"
                    class="pa-2"
                    alt="here"
                  />
                </div>
              </v-card-actions>
            </v-card>
          </template>
        </v-col>
        <v-col cols="3">
          <template>
            <v-card tile width="400">
              <v-img
                class="white--text align-end"
                height="250px"
                src="../../assets/images/bike4.png"
              >
              </v-img>

              <v-card-actions class="black white--text pa-0 customCardShadow">
                <v-btn color="white" text> More... </v-btn>

                <v-spacer></v-spacer>
                <div class="customCardBg pa-0 ma-0">
                  <img
                    src="../../assets/images/card_bg.png"
                    width="60px"
                    class="pa-2"
                    alt="here"
                  />
                </div>
              </v-card-actions>
            </v-card>
          </template>
        </v-col>
        <v-col cols="3">
          <template>
            <v-card tile width="400">
              <v-img
                class="white--text align-end"
                height="250px"
                src="../../assets/images/bike2.png"
              >
              </v-img>

              <v-card-actions class="black white--text pa-0 customCardShadow">
                <v-btn color="white" text> More... </v-btn>

                <v-spacer></v-spacer>
                <div class="customCardBg pa-0 ma-0">
                  <img
                    src="../../assets/images/card_bg.png"
                    width="60px"
                    class="pa-2"
                    alt="here"
                  />
                </div>
              </v-card-actions>
            </v-card>
          </template>
        </v-col>
      </v-row>
      <v-row class="d-flex justify-center">
        <v-col cols="3">
          <template>
            <v-card tile width="400">
              <v-img
                class="white--text align-end"
                height="250px"
                src="../../assets/images/bike1.png"
              >
              </v-img>

              <v-card-actions class="black white--text pa-0 customCardShadow">
                <v-btn color="white" text> More... </v-btn>

                <v-spacer></v-spacer>
                <div class="customCardBg pa-0 ma-0">
                  <img
                    src="../../assets/images/card_bg.png"
                    width="60px"
                    class="pa-2"
                    alt="here"
                  />
                </div>
              </v-card-actions>
            </v-card>
          </template>
        </v-col>
        <v-col cols="3">
          <template>
            <v-card tile width="400">
              <v-img
                class="white--text align-end"
                height="250px"
                src="../../assets/images/bike3.png"
              >
              </v-img>

              <v-card-actions class="black white--text pa-0 customCardShadow">
                <v-btn color="white" text> More... </v-btn>

                <v-spacer></v-spacer>
                <div class="customCardBg pa-0 ma-0">
                  <img
                    src="../../assets/images/card_bg.png"
                    width="60px"
                    class="pa-2"
                    alt="here"
                  />
                </div>
              </v-card-actions>
            </v-card>
          </template>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
  <script>
export default {
  data() {
    return {
      currentSlide: 1,
      // trendingSlide: 2,
    };
  },
};
</script>
  
  <style scoped>
.v-pagination__item {
  margin: 0 5px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
  transition: background-color 0.2s;
}

.v-pagination__item--active {
  background-color: #2196f3;
}
</style>