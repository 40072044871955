<template>
  <v-app class="red">
    <!-- <v-app-bar app elevation="0" color="Charcoal" id="myAppBar" class="d-flex justify-center" height="56">
        <h1 class="Mainlogo mx-2 " justify="center" @click="$router.push('/')" >BIKEZ</h1>
    </v-app-bar> -->
    <!-- <v-app-bar app elevation="0"  class="Customheader" height="56">
      <v-btn depressed icon elevation="0">
        <v-icon>mdi-menu</v-icon>
      </v-btn> 
        <h1 class="Mainlogo mx-2" @click="$router.push('/')" >Bikez</h1>
      
      <v-spacer></v-spacer>
      <v-btn depressed icon elevation="0">
        <v-icon>mdi-github</v-icon>
      </v-btn>
      <v-btn depressed icon elevation="0">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </v-app-bar> -->
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-divider></v-divider>
    <v-footer color="#171717" class="">
      <v-container class="myrow">
        <v-divider dark></v-divider>
        <v-row class="d-flex justify-center">
          <!-- <v-btn icon text depressed >
          <v-icon class="info--text">mdi-facebook</v-icon>
         </v-btn> 
         <v-btn icon text depressed >
          <v-icon class="red--text">mdi-youtube</v-icon>
         </v-btn>  -->
        </v-row>
        <v-row class="d-flex justify-start pa-0 align-center">
       <v-col cols="10">
        <span class="white--text body-2 small">
          @2023. www.bikez.pk | Visitor Agreement & Privacy Policy</span
        >
       </v-col>
          <v-spacer></v-spacer>
          <v-col cols="2">
            <v-img
              width="50%"
              class="white--text"
              src="../src/assets/white_bg.png"
              alt="Bike"
            >
            </v-img>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
// import HomeComp from './components/Navigation/HomeComp';
// import BikesDetails from './components/Navigation/BikesDetails';
// import MainComp from "./components/Maincomponents/MainComp";

export default {
  name: "App",

  components: {
    // MainComp,
    // HomeComp,
    // BikesDetails
  },

  data: () => ({
    //
  }),
};
</script>
<style>
@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@1,100&family=Open+Sans:wght@300;500&family=Poppins:wght@100;200;300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@1,100&family=Open+Sans:wght@300;500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@1,100&family=Open+Sans:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins&display=swap);
@import url(https://cdn.jsdelivr.net/npm/remixicon@2.2.0/fonts/remixicon.css);
@import url(assets/CSS/Style.css);
@import url(https://fonts.googleapis.com/css2?family=wght@1,200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Kanit:wght@500&family=Lato:ital,wght@1,100&family=Open+Sans:wght@300;500&family=Poppins:wght@100;200;300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Kanit:wght@500&family=Lato:ital,wght@1,100&family=Open+Sans:wght@300;500&family=Poppins:wght@100;200;300&display=swap);
</style>

<!-- @import url(assets/CSS/icons.css); -->