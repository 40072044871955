import Vue from 'vue'
import VueRouter from 'vue-router'
import MainComp from '../components/Maincomponents/MainComp.vue'
import BikesDetails from '../components/Bikes/BikesDetails.vue'
import Allbikes from '../components/Bikes/AllBikes.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'MainComp',
    component: MainComp
  },
  {
    path: '/BikesDetails',
    name: 'BikesDetails',
    component: BikesDetails
  },
  {
    path: '/Allbikes',
    name: 'Allbikes',
    component: Allbikes
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
