import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import store from './store'
import router from './router';
Vue.config.productionTip = false
import { Carousel3d, Slide } from 'vue-carousel-3d';
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
Vue.use(Carousel3d,Slide,VueperSlides, VueperSlide );

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
