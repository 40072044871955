<template>
  <v-app id="MainComp" class="mt-0 pt-0">
    <v-main class="mt-0 pt-0">
      <!--***********************************************************Main Section***********************************************************-->
      <v-container class="customBanner" fluid>
        <v-row class="text-center">
          <v-col cols="12">
            <h2 class="BannerHeading mt-15 BikeName">FIND THE RIGHT BIKE</h2>
            <h3 class="BannerHeading3 font-weight-thin">
              Get Comprehensive Information on Bike Prices, Specs, Reviews &
              More!
            </h3>
          </v-col>
        </v-row>

        <v-row class="d-flex justify-center text-center mt-10">
          <v-col cols="10" md="4" block class="bottmMargin">
            <v-btn-toggle
              v-model="toggle_exclusive"
              class="bikeDropdown"
              hide-details
            >
              <v-select
                :items="dropdown_bike"
                label="Select Bike"
                solo
                hide-details
                class="bikeDropdown"
              ></v-select>
              <!-- <v-divider vertical></v-divider> -->
              <v-select
                :items="dropdown_Price"
                label="Select Price"
                solo
                hide-details
                class="bikeDropdown"
                offset="z"
              ></v-select>
              <!-- <v-btn class="red darken-2" >
                  <v-icon class="white--text pa-0" >
                    mdi-magnify
                  </v-icon>
                </v-btn> -->
              <v-btn
                class="darken-1"
                tile
                depressed
                dark
                color="red text--white pb-1"
                v-model="SearchItem"
                @click="(event) => SearchItems()"
                >Search</v-btn
              >
            </v-btn-toggle>
          </v-col>
        </v-row>
      </v-container>

      <!--***********************************************************Imported component for the viedoes slider ########################################-->
      <v-container
        fluid
        style="
          background: radial-gradient(circle, rgb(71, 76, 74) 20%, #343636 80%);
        "
        class="lighten-4"
      >
        <v-container class="myrow">
          <v-row>
            <v-col cols="12">
              <circleSlide />
            </v-col>
          </v-row>
        </v-container>
      </v-container>

      <!--***********************************************************Tabs Section***********************************************************-->
      <v-container fluid class="white">
        <v-container class="myrow">
          <v-row>
            <v-col cols="12" class="text-center">
              <h3 class="browsebikeHeading BikeName">Browse Bikes By</h3>
            </v-col>
            <v-col
              cols="12"
              md="12"
              class="text-center d-flex justify-center pa-0"
            >
              <v-card elevation="0" width="100%">
                <v-tabs fixed-tabs show-arrows>
                  <v-tab class="customTab">Brand</v-tab>
                  <v-tab class="customTab">Budget</v-tab>
                  <v-tab class="customTab">Displaciment</v-tab>
                  <v-tab class="customTab">Body Style</v-tab>
                  <v-tab class="customTab">Electric</v-tab>

                  <v-tab-item class="pt-5">
                    <div class="d-flex align-center CustomHeight">
                      <v-container>
                        <v-row class="d-flex justify-center align-center">
                          <v-col cols="2">
                            <img
                              src="../../assets/images/honda.jpeg"
                              width="100"
                              class="TabImages"
                              alt=""
                            />
                            <p class="tabText">Honda</p>
                          </v-col>
                          <v-col cols="2">
                            <img
                              src="../../assets/images/yamaha.jpeg"
                              width="100"
                              class="TabImages"
                              alt=""
                            />
                            <p class="tabText">Yamaha</p>
                          </v-col>
                          <v-col cols="2">
                            <img
                              src="../../assets/images/suzuki.jpeg"
                              width="100"
                              class="TabImages"
                              alt=""
                            />
                            <p class="tabText">Suzuki</p>
                          </v-col>
                          <v-col cols="2">
                            <img
                              src="../../assets/images/hero.jpeg"
                              width="100"
                              class="TabImages"
                              alt=""
                            />
                            <p class="tabText">Hero</p>
                          </v-col>
                          <v-col cols="2">
                            <img
                              src="../../assets/images/jawa.jpeg"
                              width="100"
                              class="TabImages"
                              alt=""
                            />
                            <p class="tabText">Jawa</p>
                          </v-col>
                        </v-row>
                        <v-row class="d-flex justify-center">
                          <v-col cols="2">
                            <img
                              src="../../assets/images/hero.jpeg"
                              width="100"
                              class="TabImages"
                              alt=""
                            />
                            <p class="tabText">Hero</p>
                          </v-col>

                          <v-col cols="2">
                            <img
                              src="../../assets/images/suzuki.jpeg"
                              width="100"
                              class="TabImages"
                              alt=""
                            />
                            <p class="tabText">Suzuki</p>
                          </v-col>

                          <v-col cols="2">
                            <img
                              src="../../assets/images/jawa.jpeg"
                              width="100"
                              class="TabImages"
                              alt=""
                            />
                            <p class="tabText">Jawa</p>
                          </v-col>
                          <v-col cols="2">
                            <img
                              src="../../assets/images/yamaha.jpeg"
                              width="100"
                              class="TabImages"
                              alt=""
                            />
                            <p class="tabText">Yamaha</p>
                          </v-col>
                          <v-col cols="2">
                            <img
                              src="../../assets/images/honda.jpeg"
                              width="100"
                              class="TabImages"
                              alt=""
                            />
                            <p class="tabText">Honda</p>
                          </v-col>
                        </v-row>
                      </v-container>
                    </div>
                  </v-tab-item>

                  <v-tab-item class="pt-5">
                    <div class="d-flex align-center CustomHeight">
                      <v-container class="">
                        <v-row>
                          <v-col cols="3">
                            <v-card
                              class="rounded-0 priceCard"
                              elevation="0"
                              outlined
                            >
                              <h3 class="pa-6">Upto 100cc</h3>
                            </v-card>
                          </v-col>
                          <v-col cols="3">
                            <v-card
                              class="rounded-0 priceCard"
                              elevation="0"
                              outlined
                            >
                              <h3 class="pa-6">100cc - 110cc</h3>
                            </v-card>
                          </v-col>
                          <v-col cols="3">
                            <v-card
                              class="rounded-0 priceCard"
                              elevation="0"
                              outlined
                            >
                              <h3 class="pa-6">110cc - 140cc</h3>
                            </v-card>
                          </v-col>
                          <v-col cols="3">
                            <v-card
                              class="rounded-0 priceCard"
                              elevation="0"
                              outlined
                            >
                              <h3 class="pa-6">140cc - 180cc</h3>
                            </v-card>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="3">
                            <v-card
                              class="rounded-0 priceCard"
                              elevation="0"
                              outlined
                            >
                              <h3 class="pa-6">Upto 100cc</h3>
                            </v-card>
                          </v-col>
                          <v-col cols="3">
                            <v-card
                              class="rounded-0 priceCard"
                              elevation="0"
                              outlined
                            >
                              <h3 class="pa-6">100cc - 110cc</h3>
                            </v-card>
                          </v-col>
                          <v-col cols="3">
                            <v-card
                              class="rounded-0 priceCard"
                              elevation="0"
                              outlined
                            >
                              <h3 class="pa-6">110cc - 140cc</h3>
                            </v-card>
                          </v-col>
                          <v-col cols="3">
                            <v-card
                              class="rounded-0 priceCard"
                              elevation="0"
                              outlined
                            >
                              <h3 class="pa-6">140cc - 180cc</h3>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-container>
                    </div>
                  </v-tab-item>

                  <v-tab-item class="pt-5">
                    <div class="d-flex align-center CustomHeight">
                      <v-container>
                        <v-row>
                          <v-col cols="3">
                            <v-card
                              class="rounded-0 priceCard"
                              elevation="0"
                              outlined
                            >
                              <h3 class="pa-6">Upto 100cc</h3>
                            </v-card>
                          </v-col>
                          <v-col cols="3">
                            <v-card
                              class="rounded-0 priceCard"
                              elevation="0"
                              outlined
                            >
                              <h3 class="pa-6">100cc - 110cc</h3>
                            </v-card>
                          </v-col>
                          <v-col cols="3">
                            <v-card
                              class="rounded-0 priceCard"
                              elevation="0"
                              outlined
                            >
                              <h3 class="pa-6">110cc - 140cc</h3>
                            </v-card>
                          </v-col>
                          <v-col cols="3">
                            <v-card
                              class="rounded-0 priceCard"
                              elevation="0"
                              outlined
                            >
                              <h3 class="pa-6">140cc - 180cc</h3>
                            </v-card>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="3">
                            <v-card
                              class="rounded-0 priceCard"
                              elevation="0"
                              outlined
                            >
                              <h3 class="pa-6">180cc - 200cc</h3>
                            </v-card>
                          </v-col>
                          <v-col cols="3">
                            <v-card
                              class="rounded-0 priceCard"
                              elevation="0"
                              outlined
                            >
                              <h3 class="pa-6">200cc - 250cc</h3>
                            </v-card>
                          </v-col>
                          <v-col cols="3">
                            <v-card
                              class="rounded-0 priceCard"
                              elevation="0"
                              outlined
                            >
                              <h3 class="pa-6">250cc - 500cc</h3>
                            </v-card>
                          </v-col>
                          <v-col cols="3">
                            <v-card
                              class="rounded-0 priceCard"
                              elevation="0"
                              outlined
                            >
                              <h3 class="pa-6">Above 500cc</h3>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-container>
                    </div>
                  </v-tab-item>

                  <v-tab-item class="pt-5">
                    <div class="d-flex align-center CustomHeight">
                      <v-container>
                        <v-row class="d-flex justify-center">
                          <v-col cols="2">
                            <v-card elevation="0" class="BodyStyleCard pt-2">
                              <img
                                src="../../assets/images/illustrations/adventure.png"
                                alt=""
                              />
                              <p>Adventure</p>
                            </v-card>
                          </v-col>

                          <v-col cols="2">
                            <v-card elevation="0" class="BodyStyleCard pt-2">
                              <img
                                src="../../assets/images/illustrations/super-bikes.png"
                                alt=""
                              />
                              <p>Super Bike</p>
                            </v-card>
                          </v-col>
                          <v-col cols="2">
                            <v-card elevation="0" class="BodyStyleCard pt-2">
                              <img
                                src="../../assets/images/illustrations/super-bikes.png"
                                alt=""
                              />
                              <p>Super Bike</p>
                            </v-card>
                          </v-col>
                          <v-col cols="2">
                            <v-card elevation="0" class="BodyStyleCard pt-2">
                              <img
                                src="../../assets/images/illustrations/street-bikes.png"
                                alt=""
                              />
                              <p>Street Bike</p>
                            </v-card>
                          </v-col>
                          <v-col cols="2">
                            <v-card elevation="0" class="BodyStyleCard pt-2">
                              <img
                                src="../../assets/images/illustrations/cafe-racer.png"
                                alt=""
                              />
                              <p>Cafe Racer</p>
                            </v-card>
                          </v-col>
                          <v-col cols="2">
                            <v-card elevation="0" class="BodyStyleCard pt-2">
                              <img
                                src="../../assets/images/illustrations/commuter.png"
                                alt=""
                              />
                              <p>Commuter</p>
                            </v-card>
                          </v-col>
                        </v-row>
                        <v-row class="d-flex justify-center">
                          <v-col cols="2">
                            <v-card elevation="0" class="BodyStyleCard pt-2">
                              <img
                                src="../../assets/images/illustrations/maxi-scooters.png"
                                alt=""
                              />
                              <p>Maxi Scooters</p>
                            </v-card>
                          </v-col>

                          <v-col cols="2">
                            <v-card elevation="0" class="BodyStyleCard pt-2">
                              <img
                                src="../../assets/images/illustrations/moped.png"
                                alt=""
                              />
                              <p>Moped</p>
                            </v-card>
                          </v-col>

                          <v-col cols="2">
                            <v-card elevation="0" class="BodyStyleCard pt-2">
                              <img
                                src="../../assets/images/illustrations/scooters.png"
                                alt=""
                              />
                              <p>scooter</p>
                            </v-card>
                          </v-col>
                          <v-col cols="2">
                            <v-card elevation="0" class="BodyStyleCard pt-2">
                              <img
                                src="../../assets/images/illustrations/scrambler.png"
                                alt=""
                              />
                              <p>Scrambler</p>
                            </v-card>
                          </v-col>
                          <v-col cols="2">
                            <v-card elevation="0" class="BodyStyleCard pt-2">
                              <img
                                src="../../assets/images/illustrations/scrambler.png"
                                alt=""
                              />
                              <p>Scrambler</p>
                            </v-card>
                          </v-col>
                          <v-col cols="2">
                            <v-card elevation="0" class="BodyStyleCard pt-2">
                              <img
                                src="../../assets/images/illustrations/tourer (1).png"
                                alt=""
                              />
                              <p>Tourer</p>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-container>
                    </div>
                  </v-tab-item>

                  <v-tab-item class="pt-5">
                    <div class="d-flex align-center CustomHeight">
                      <v-container>
                        <v-row class="d-flex justify-center">
                          <v-col cols="2">
                            <v-card elevation="0" class="BodyStyleCard pt-2">
                              <img
                                src="../../assets/images/illustrations/adventure.png"
                                alt=""
                              />
                              <p>Adventure</p>
                            </v-card>
                          </v-col>

                          <v-col cols="2">
                            <v-card elevation="0" class="BodyStyleCard pt-2">
                              <img
                                src="../../assets/images/illustrations/super-bikes.png"
                                alt=""
                              />
                              <p>Super Bike</p>
                            </v-card>
                          </v-col>
                          <v-col cols="2">
                            <v-card elevation="0" class="BodyStyleCard pt-2">
                              <img
                                src="../../assets/images/illustrations/super-bikes.png"
                                alt=""
                              />
                              <p>Super Bike</p>
                            </v-card>
                          </v-col>
                          <v-col cols="2">
                            <v-card elevation="0" class="BodyStyleCard pt-2">
                              <img
                                src="../../assets/images/illustrations/street-bikes.png"
                                alt=""
                              />
                              <p>Street Bike</p>
                            </v-card>
                          </v-col>
                          <v-col cols="2">
                            <v-card elevation="0" class="BodyStyleCard pt-2">
                              <img
                                src="../../assets/images/illustrations/cafe-racer.png"
                                alt=""
                              />
                              <p>Cafe Racer</p>
                            </v-card>
                          </v-col>
                          <v-col cols="2">
                            <v-card elevation="0" class="BodyStyleCard pt-2">
                              <img
                                src="../../assets/images/illustrations/commuter.png"
                                alt=""
                              />
                              <p>Commuter</p>
                            </v-card>
                          </v-col>
                        </v-row>
                        <v-row class="d-flex justify-center">
                          <v-col cols="2">
                            <v-card elevation="0" class="BodyStyleCard pt-2">
                              <img
                                src="../../assets/images/illustrations/maxi-scooters.png"
                                alt=""
                              />
                              <p>Maxi Scooters</p>
                            </v-card>
                          </v-col>

                          <v-col cols="2">
                            <v-card elevation="0" class="BodyStyleCard pt-2">
                              <img
                                src="../../assets/images/illustrations/moped.png"
                                alt=""
                              />
                              <p>Moped</p>
                            </v-card>
                          </v-col>

                          <v-col cols="2">
                            <v-card elevation="0" class="BodyStyleCard pt-2">
                              <img
                                src="../../assets/images/illustrations/scooters.png"
                                alt=""
                              />
                              <p>scooter</p>
                            </v-card>
                          </v-col>
                          <v-col cols="2">
                            <v-card elevation="0" class="BodyStyleCard pt-2">
                              <img
                                src="../../assets/images/illustrations/scrambler.png"
                                alt=""
                              />
                              <p>Scrambler</p>
                            </v-card>
                          </v-col>
                          <v-col cols="2">
                            <v-card elevation="0" class="BodyStyleCard pt-2">
                              <img
                                src="../../assets/images/illustrations/scrambler.png"
                                alt=""
                              />
                              <p>Scrambler</p>
                            </v-card>
                          </v-col>
                          <v-col cols="2">
                            <v-card elevation="0" class="BodyStyleCard pt-2">
                              <img
                                src="../../assets/images/illustrations/tourer (1).png"
                                alt=""
                              />
                              <p>Tourer</p>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-container>
                    </div>
                  </v-tab-item>
                </v-tabs>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
      <!--***********************************************************Featured  bikes Slider Section***********************************************************-->
      <v-container fluid class="pa-0 pb-10 MyBackground">
        <v-container class="myrow pb-16">
          <h3 class="text-center my-10 BikeName white--text">FEATURED BIKES</h3>
          <v-row class="d-flex justify-center">
            <v-col cols="12" class="pa-0">
              <v-card elevation="0">
                <v-carousel
                  hide-delimiters
                  height="100%"
                  class="CarouselGredient"
                >
                  <template v-slot:prev="{ on, attrs }">
                    <v-btn
                      width="10px"
                      text
                      v-bind="attrs"
                      v-on="on"
                      class="pa-0 myCarouselNavagationpre"
                    >
                      <v-img
                        class="ml-3 bikesleftIcon"
                        width="30rem"
                        src="../../assets/images/specialCards/whitArrow.png"
                      >
                      </v-img>
                    </v-btn>
                  </template>
                  <template v-slot:next="{ on, attrs }">
                    <v-btn
                      text
                      width="10px"
                      v-bind="attrs"
                      v-on="on"
                      class="pa-0 myCarouselNavagationnext"
                    >
                      <v-img
                        class="mr-3"
                        width="30rem"
                        src="../../assets/images/specialCards/whitArrow.png"
                      >
                      </v-img>
                      <!-- <v-icon class="black--text">mdi-chevron-right</v-icon> -->
                    </v-btn>
                  </template>
                  <v-carousel-item class="pa-0 myCarItem ma-0">
                    <div class="d-flex justify-center pa-0 ma-0">
                      <v-card
                        elevation="0"
                        outlined
                        width="300"
                        width-md="450"
                        class="grey lighten-3 CustomCardShadow ma-4"
                        to="/BikesDetails"
                      >
                        <v-img
                          class="white--text align-end"
                          height="200px"
                          src="../../assets/images/cards/cardBike2.jpeg"
                        >
                        </v-img>
                        <v-divider></v-divider>
                        <v-card-subtitle class="pb-0 pt-5 text-center">
                          <a
                            href=""
                            class="text-decoration-none black--text text-center subtitle-1"
                          >
                            TVS Raider 125</a
                          >
                          <div class="">
                            <a
                              href=""
                              class="text-decoration-none grey--text title"
                              >RS. 12000</a
                            >
                          </div>
                        </v-card-subtitle>

                        <v-card-text
                          class="text--primary pt-5 CardText text-center"
                        >
                          <div class="d-flex align-items-center justify-center">
                            <div class="section">
                              <h3 class="subtitle-2">Power</h3>
                              <p>225 cc</p>
                            </div>
                            <span class="divider display-5">|</span>
                            <div class="section">
                              <h3 class="subtitle-2">Speed</h3>
                              <p>250 km/h</p>
                            </div>
                            <span class="divider">|</span>
                            <div class="section">
                              <h3 class="subtitle-2">Tank</h3>
                              <p>5 liters</p>
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                      <v-card
                        elevation="0"
                        outlined
                        width="300"
                        class="grey lighten-3 CustomCardShadow ma-4"
                        to="/BikesDetails"
                      >
                        <v-img
                          class="white--text align-end"
                          height="200px"
                          src="../../assets/images/cards/Cardbike3.jpeg"
                        >
                        </v-img>
                        <v-divider></v-divider>

                        <v-card-subtitle class="pb-0 pt-5 text-center">
                          <a
                            href=""
                            class="text-decoration-none black--text text-center subtitle-1"
                          >
                            TVS Raider 125</a
                          >
                          <div class="">
                            <a
                              href=""
                              class="text-decoration-none grey--text title"
                              >RS. 12000</a
                            >
                          </div>
                        </v-card-subtitle>
                        <v-card-text
                          class="text--primary pt-5 CardText text-center"
                        >
                          <div class="d-flex align-items-center justify-center">
                            <div class="section">
                              <h3 class="subtitle-2">Power</h3>
                              <p>225 cc</p>
                            </div>
                            <span class="divider display-5">|</span>
                            <div class="section">
                              <h3 class="subtitle-2">Speed</h3>
                              <p>250 km/h</p>
                            </div>
                            <span class="divider">|</span>
                            <div class="section">
                              <h3 class="subtitle-2">Tank</h3>
                              <p>5 liters</p>
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                      <v-card
                        elevation="0"
                        outlined
                        width="300"
                        class="grey lighten-3 CustomCardShadow ma-4"
                        to="/BikesDetails"
                      >
                        <v-img
                          class="white--text align-end"
                          height="200px"
                          src="../../assets/images/cards/Cardbike4.jpeg"
                        >
                        </v-img>
                        <v-divider></v-divider>

                        <v-card-subtitle class="pb-0 pt-5 text-center">
                          <a
                            href=""
                            class="text-decoration-none black--text text-center subtitle-1"
                          >
                            TVS Raider 125</a
                          >
                          <div class="">
                            <a
                              href=""
                              class="text-decoration-none grey--text title"
                              >RS. 12000</a
                            >
                          </div>
                        </v-card-subtitle>

                        <v-card-text
                          class="text--primary pt-5 CardText text-center"
                        >
                          <div class="d-flex align-items-center justify-center">
                            <div class="section">
                              <h3 class="subtitle-2">Power</h3>
                              <p>225 cc</p>
                            </div>
                            <span class="divider display-5">|</span>
                            <div class="section">
                              <h3 class="subtitle-2">Speed</h3>
                              <p>250 km/h</p>
                            </div>
                            <span class="divider">|</span>
                            <div class="section">
                              <h3 class="subtitle-2">Tank</h3>
                              <p>5 liters</p>
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                    </div>
                  </v-carousel-item>
                  <v-carousel-item class="pa-0 myCarItem ma-0">
                    <div class="d-flex justify-center pa-0 ma-0">
                      <v-card
                        elevation="0"
                        outlined
                        width="300"
                        class="grey lighten-3 CustomCardShadow ma-4"
                      >
                        <v-img
                          class="white--text align-end"
                          height="200px"
                          src="../../assets/images/cards/cardBike2.jpeg"
                        >
                        </v-img>
                        <v-divider></v-divider>
                        <v-card-subtitle class="pb-0 pt-5 text-center">
                          <a
                            href=""
                            class="text-decoration-none black--text text-center subtitle-1"
                          >
                            TVS Raider 125</a
                          >
                          <div class="">
                            <a
                              href=""
                              class="text-decoration-none grey--text title"
                              >RS. 12000</a
                            >
                          </div>
                        </v-card-subtitle>

                        <v-card-text
                          class="text--primary pt-5 CardText text-center"
                        >
                          <div class="d-flex align-items-center justify-center">
                            <div class="section">
                              <h3 class="subtitle-2">Power</h3>
                              <p>225 cc</p>
                            </div>
                            <span class="divider display-5">|</span>
                            <div class="section">
                              <h3 class="subtitle-2">Speed</h3>
                              <p>250 km/h</p>
                            </div>
                            <span class="divider">|</span>
                            <div class="section">
                              <h3 class="subtitle-2">Tank</h3>
                              <p>5 liters</p>
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                      <v-card
                        elevation="0"
                        outlined
                        width="300"
                        class="grey lighten-3 CustomCardShadow ma-4"
                      >
                        <v-img
                          class="white--text align-end"
                          height="200px"
                          src="../../assets/images/cards/Cardbike3.jpeg"
                        >
                        </v-img>
                        <v-divider></v-divider>
                        <v-card-subtitle class="pb-0 pt-5 text-center">
                          <a
                            href=""
                            class="text-decoration-none black--text text-center subtitle-1"
                          >
                            TVS Raider 125</a
                          >
                          <div class="">
                            <a
                              href=""
                              class="text-decoration-none grey--text title"
                              >RS. 12000</a
                            >
                          </div>
                        </v-card-subtitle>

                        <v-card-text
                          class="text--primary pt-5 CardText text-center"
                        >
                          <div class="d-flex align-items-center justify-center">
                            <div class="section">
                              <h3 class="subtitle-2">Power</h3>
                              <p>225 cc</p>
                            </div>
                            <span class="divider display-5">|</span>
                            <div class="section">
                              <h3 class="subtitle-2">Speed</h3>
                              <p>250 km/h</p>
                            </div>
                            <span class="divider">|</span>
                            <div class="section">
                              <h3 class="subtitle-2">Tank</h3>
                              <p>5 liters</p>
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                      <v-card
                        elevation="0"
                        outlined
                        width="300"
                        class="grey lighten-3 CustomCardShadow ma-4"
                      >
                        <v-img
                          class="white--text align-end"
                          height="200px"
                          src="../../assets/images/cards/Cardbike4.jpeg"
                        >
                        </v-img>
                        <v-divider></v-divider>
                        <v-card-subtitle class="pb-0 pt-5 text-center">
                          <a
                            href=""
                            class="text-decoration-none black--text text-center subtitle-1"
                          >
                            TVS Raider 125</a
                          >
                          <div class="">
                            <a
                              href=""
                              class="text-decoration-none grey--text title"
                              >RS. 12000</a
                            >
                          </div>
                        </v-card-subtitle>

                        <v-card-text
                          class="text--primary pt-5 CardText text-center"
                        >
                          <div class="d-flex align-items-center justify-center">
                            <div class="section">
                              <h3 class="subtitle-2">Power</h3>
                              <p>225 cc</p>
                            </div>
                            <span class="divider display-5">|</span>
                            <div class="section">
                              <h3 class="subtitle-2">Speed</h3>
                              <p>250 km/h</p>
                            </div>
                            <span class="divider">|</span>
                            <div class="section">
                              <h3 class="subtitle-2">Tank</h3>
                              <p>5 liters</p>
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                    </div>
                  </v-carousel-item>
                  <v-carousel-item class="myCarItem pa-0 ma-0">
                    <div class="d-flex justify-center pa-0 ma-0">
                      <v-card
                        elevation="0"
                        outlined
                        width="300"
                        class="grey lighten-3 CustomCardShadow ma-4"
                      >
                        <v-img
                          class="white--text align-end"
                          height="200px"
                          src="../../assets/images/cards/cardBike2.jpeg"
                        >
                        </v-img>
                        <v-divider></v-divider>
                        <v-card-subtitle class="pb-0 pt-5 text-center">
                          <a
                            href=""
                            class="text-decoration-none black--text text-center subtitle-1"
                          >
                            TVS Raider 125</a
                          >
                          <div class="">
                            <a
                              href=""
                              class="text-decoration-none grey--text title"
                              >RS. 12000</a
                            >
                          </div>
                        </v-card-subtitle>

                        <v-card-text
                          class="text--primary pt-5 CardText text-center"
                        >
                          <div class="d-flex align-items-center justify-center">
                            <div class="section">
                              <h3 class="subtitle-2">Power</h3>
                              <p>225 cc</p>
                            </div>
                            <span class="divider display-5">|</span>
                            <div class="section">
                              <h3 class="subtitle-2">Speed</h3>
                              <p>250 km/h</p>
                            </div>
                            <span class="divider">|</span>
                            <div class="section">
                              <h3 class="subtitle-2">Tank</h3>
                              <p>5 liters</p>
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                      <v-card
                        elevation="0"
                        outlined
                        width="300"
                        class="grey lighten-3 CustomCardShadow ma-4"
                      >
                        <v-img
                          class="white--text align-end"
                          height="200px"
                          src="../../assets/images/cards/Cardbike3.jpeg"
                        >
                        </v-img>
                        <v-divider></v-divider>
                        <v-card-subtitle class="pb-0 pt-5 text-center">
                          <a
                            href=""
                            class="text-decoration-none black--text text-center subtitle-1"
                          >
                            TVS Raider 125</a
                          >
                          <div class="">
                            <a
                              href=""
                              class="text-decoration-none grey--text title"
                              >RS. 12000</a
                            >
                          </div>
                        </v-card-subtitle>

                        <v-card-text
                          class="text--primary pt-5 CardText text-center"
                        >
                          <div class="d-flex align-items-center justify-center">
                            <div class="section">
                              <h3 class="subtitle-2">Power</h3>
                              <p>225 cc</p>
                            </div>
                            <span class="divider display-5">|</span>
                            <div class="section">
                              <h3 class="subtitle-2">Speed</h3>
                              <p>250 km/h</p>
                            </div>
                            <span class="divider">|</span>
                            <div class="section">
                              <h3 class="subtitle-2">Tank</h3>
                              <p>5 liters</p>
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                      <v-card
                        elevation="0"
                        outlined
                        width="300"
                        class="grey lighten-3 CustomCardShadow ma-4"
                      >
                        <v-img
                          class="white--text align-end"
                          height="200px"
                          src="../../assets/images/cards/Cardbike4.jpeg"
                        >
                        </v-img>
                        <v-divider></v-divider>
                        <v-card-subtitle class="pb-0 pt-5 text-center">
                          <a
                            href=""
                            class="text-decoration-none black--text text-center subtitle-1"
                          >
                            TVS Raider 125</a
                          >
                          <div class="">
                            <a
                              href=""
                              class="text-decoration-none grey--text title"
                              >RS. 12000</a
                            >
                          </div>
                        </v-card-subtitle>

                        <v-card-text
                          class="text--primary pt-5 CardText text-center"
                        >
                          <div class="d-flex align-items-center justify-center">
                            <div class="section">
                              <h3 class="subtitle-2">Power</h3>
                              <p>225 cc</p>
                            </div>
                            <span class="divider display-5">|</span>
                            <div class="section">
                              <h3 class="subtitle-2">Speed</h3>
                              <p>250 km/h</p>
                            </div>
                            <span class="divider">|</span>
                            <div class="section">
                              <h3 class="subtitle-2">Tank</h3>
                              <p>5 liters</p>
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                    </div>
                  </v-carousel-item>
                </v-carousel>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-container>
      <!--*********************************************************** Top Trending***********************************************************-->
      <v-container fluid class="pa-0 pb-15">
        <v-container class="myrow pa-0 pb-5">
          <h3 class="text-center my-10 BikeName">TOP TRENDING</h3>
          <v-row class="d-flex justify-center">
            <v-col cols="12 " class="pa-0">
              <v-card elevation="0 ">
                <v-carousel
                  hide-delimiters
                  height="100%"
                  :cycle="false"
                  :autoplay="false"
                  :interval="3300"
                  :reverse="true"
                  class="myCarousel carouselBG"
                >
                  <div></div>
                  <template v-slot:prev="{ on, attrs }">
                    <v-btn text class="pa-0 myCarouselNavagationpre">
                      <v-img
                        v-bind="attrs"
                        v-on="on"
                        class="ml-3 bikesleftIcon"
                        width="1px"
                        src="../../assets/images/specialCards/right-arrow.png"
                      >
                      </v-img>
                    </v-btn>
                  </template>
                  <template v-slot:next="{ on, attrs }">
                    <v-btn
                      text
                      depressed
                      v-bind="attrs"
                      v-on="on"
                      class="pa-0 myCarouselNavagationnext"
                    >
                      <v-img
                        class="mr-3"
                        width="1px"
                        src="../../assets/images/specialCards/right-arrow.png"
                      >
                      </v-img>
                      <!-- <v-icon class="black--text">mdi-chevron-right</v-icon> -->
                    </v-btn>
                  </template>
                  <v-carousel-item class="pa-0 myCarItem ma-0">
                    <div class="d-flex justify-center pa-0 ma-0">
                      <v-card
                        elevation="0"
                        outlined
                        width="300"
                        width-md="450"
                        class="grey lighten-3 CustomCardShadow ma-4"
                        to="/BikesDetails"
                      >
                        <v-img
                          class="white--text align-end"
                          height="200px"
                          src="../../assets/images/cards/cardBike2.jpeg"
                        >
                        </v-img>
                        <v-divider></v-divider>
                        <v-card-subtitle class="pb-0 pt-5 text-center">
                          <a
                            href=""
                            class="text-decoration-none black--text text-center subtitle-1"
                          >
                            TVS Raider 125</a
                          >
                          <div class="">
                            <a
                              href=""
                              class="text-decoration-none grey--text title"
                              >RS. 12000</a
                            >
                          </div>
                        </v-card-subtitle>

                        <v-card-text
                          class="text--primary pt-5 CardText text-center"
                        >
                          <div class="d-flex align-items-center justify-center">
                            <div class="section">
                              <h3 class="subtitle-2">Power</h3>
                              <p>225 cc</p>
                            </div>
                            <span class="divider display-5">|</span>
                            <div class="section">
                              <h3 class="subtitle-2">Speed</h3>
                              <p>250 km/h</p>
                            </div>
                            <span class="divider">|</span>
                            <div class="section">
                              <h3 class="subtitle-2">Tank</h3>
                              <p>5 liters</p>
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                      <v-card
                        elevation="0"
                        outlined
                        width="300"
                        class="grey lighten-3 CustomCardShadow ma-4"
                        to="/BikesDetails"
                      >
                        <v-img
                          class="white--text align-end"
                          height="200px"
                          src="../../assets/images/cards/Cardbike3.jpeg"
                        >
                        </v-img>
                        <v-divider></v-divider>

                        <v-card-subtitle class="pb-0 pt-5 text-center">
                          <a
                            href=""
                            class="text-decoration-none black--text text-center subtitle-1"
                          >
                            TVS Raider 125</a
                          >
                          <div class="">
                            <a
                              href=""
                              class="text-decoration-none grey--text title"
                              >RS. 12000</a
                            >
                          </div>
                        </v-card-subtitle>
                        <v-card-text
                          class="text--primary pt-5 CardText text-center"
                        >
                          <div class="d-flex align-items-center justify-center">
                            <div class="section">
                              <h3 class="subtitle-2">Power</h3>
                              <p>225 cc</p>
                            </div>
                            <span class="divider display-5">|</span>
                            <div class="section">
                              <h3 class="subtitle-2">Speed</h3>
                              <p>250 km/h</p>
                            </div>
                            <span class="divider">|</span>
                            <div class="section">
                              <h3 class="subtitle-2">Tank</h3>
                              <p>5 liters</p>
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                      <v-card
                        elevation="0"
                        outlined
                        width="300"
                        class="grey lighten-3 CustomCardShadow ma-4"
                        to="/BikesDetails"
                      >
                        <v-img
                          class="white--text align-end"
                          height="200px"
                          src="../../assets/images/cards/Cardbike4.jpeg"
                        >
                        </v-img>
                        <v-divider></v-divider>

                        <v-card-subtitle class="pb-0 pt-5 text-center">
                          <a
                            href=""
                            class="text-decoration-none black--text text-center subtitle-1"
                          >
                            TVS Raider 125</a
                          >
                          <div class="">
                            <a
                              href=""
                              class="text-decoration-none grey--text title"
                              >RS. 12000</a
                            >
                          </div>
                        </v-card-subtitle>

                        <v-card-text
                          class="text--primary pt-5 CardText text-center"
                        >
                          <div class="d-flex align-items-center justify-center">
                            <div class="section">
                              <h3 class="subtitle-2">Power</h3>
                              <p>225 cc</p>
                            </div>
                            <span class="divider display-5">|</span>
                            <div class="section">
                              <h3 class="subtitle-2">Speed</h3>
                              <p>250 km/h</p>
                            </div>
                            <span class="divider">|</span>
                            <div class="section">
                              <h3 class="subtitle-2">Tank</h3>
                              <p>5 liters</p>
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                    </div>
                  </v-carousel-item>
                  <v-carousel-item class="pa-0 myCarItem ma-0">
                    <div class="d-flex justify-center pa-0 ma-0">
                      <v-card
                        elevation="0"
                        outlined
                        width="300"
                        class="grey lighten-3 CustomCardShadow ma-4"
                      >
                        <v-img
                          class=""
                          height="200px"
                          src="../../assets/images/cards/cardBike2.jpeg"
                        >
                        </v-img>
                        <v-card-subtitle class="pb-0 pt-5 text-center">
                          <a
                            href=""
                            class="text-decoration-none black--text text-center subtitle-1"
                          >
                            TVS Raider 125</a
                          >
                          <div class="">
                            <a
                              href=""
                              class="text-decoration-none grey--text title"
                              >RS. 12000</a
                            >
                          </div>
                        </v-card-subtitle>

                        <v-card-text
                          class="text--primary pt-5 CardText text-center"
                        >
                          <div class="d-flex align-items-center justify-center">
                            <div class="section">
                              <h3 class="subtitle-2">Power</h3>
                              <p>225 cc</p>
                            </div>
                            <span class="divider display-5">|</span>
                            <div class="section">
                              <h3 class="subtitle-2">Speed</h3>
                              <p>250 km/h</p>
                            </div>
                            <span class="divider">|</span>
                            <div class="section">
                              <h3 class="subtitle-2">Tank</h3>
                              <p>5 liters</p>
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                      <v-card
                        elevation="0"
                        outlined
                        width="300"
                        class="grey lighten-3 CustomCardShadow ma-4"
                      >
                        <v-img
                          class="white--text align-end"
                          height="200px"
                          src="../../assets/images/cards/Cardbike3.jpeg"
                        >
                        </v-img>
                        <v-divider></v-divider>
                        <v-card-subtitle class="pb-0 pt-5 text-center">
                          <a
                            href=""
                            class="text-decoration-none black--text text-center subtitle-1"
                          >
                            TVS Raider 125</a
                          >
                          <div class="">
                            <a
                              href=""
                              class="text-decoration-none grey--text title"
                              >RS. 12000</a
                            >
                          </div>
                        </v-card-subtitle>

                        <v-card-text
                          class="text--primary pt-5 CardText text-center"
                        >
                          <div class="d-flex align-items-center justify-center">
                            <div class="section">
                              <h3 class="subtitle-2">Power</h3>
                              <p>225 cc</p>
                            </div>
                            <span class="divider display-5">|</span>
                            <div class="section">
                              <h3 class="subtitle-2">Speed</h3>
                              <p>250 km/h</p>
                            </div>
                            <span class="divider">|</span>
                            <div class="section">
                              <h3 class="subtitle-2">Tank</h3>
                              <p>5 liters</p>
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                      <v-card
                        elevation="0"
                        outlined
                        width="300"
                        class="grey lighten-3 CustomCardShadow ma-4"
                      >
                        <v-img
                          class="white--text align-end"
                          height="200px"
                          src="../../assets/images/cards/Cardbike4.jpeg"
                        >
                        </v-img>
                        <v-divider></v-divider>
                        <v-card-subtitle class="pb-0 pt-5 text-center">
                          <a
                            href=""
                            class="text-decoration-none black--text text-center subtitle-1"
                          >
                            TVS Raider 125</a
                          >
                          <div class="">
                            <a
                              href=""
                              class="text-decoration-none grey--text title"
                              >RS. 12000</a
                            >
                          </div>
                        </v-card-subtitle>

                        <v-card-text
                          class="text--primary pt-5 CardText text-center"
                        >
                          <div class="d-flex align-items-center justify-center">
                            <div class="section">
                              <h3 class="subtitle-2">Power</h3>
                              <p>225 cc</p>
                            </div>
                            <span class="divider display-5">|</span>
                            <div class="section">
                              <h3 class="subtitle-2">Speed</h3>
                              <p>250 km/h</p>
                            </div>
                            <span class="divider">|</span>
                            <div class="section">
                              <h3 class="subtitle-2">Tank</h3>
                              <p>5 liters</p>
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                    </div>
                  </v-carousel-item>
                  <v-carousel-item class="pa-0 myCarItem ma-0">
                    <div class="d-flex justify-center pa-0 ma-0">
                      <v-card
                        elevation="0"
                        outlined
                        width="300"
                        class="grey lighten-3 CustomCardShadow ma-4"
                      >
                        <v-img
                          class="white--text align-end"
                          height="200px"
                          src="../../assets/images/cards/cardBike2.jpeg"
                        >
                        </v-img>
                        <v-divider></v-divider>
                        <v-card-subtitle class="pb-0 pt-5 text-center">
                          <a
                            href=""
                            class="text-decoration-none black--text text-center subtitle-1"
                          >
                            TVS Raider 125</a
                          >
                          <div class="">
                            <a
                              href=""
                              class="text-decoration-none grey--text title"
                              >RS. 12000</a
                            >
                          </div>
                        </v-card-subtitle>

                        <v-card-text
                          class="text--primary pt-5 CardText text-center"
                        >
                          <div class="d-flex align-items-center justify-center">
                            <div class="section">
                              <h3 class="subtitle-2">Power</h3>
                              <p>225 cc</p>
                            </div>
                            <span class="divider display-5">|</span>
                            <div class="section">
                              <h3 class="subtitle-2">Speed</h3>
                              <p>250 km/h</p>
                            </div>
                            <span class="divider">|</span>
                            <div class="section">
                              <h3 class="subtitle-2">Tank</h3>
                              <p>5 liters</p>
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                      <v-card
                        elevation="0"
                        outlined
                        width="300"
                        class="grey lighten-3 CustomCardShadow ma-4"
                      >
                        <v-img
                          class="white--text align-end"
                          height="200px"
                          src="../../assets/images/cards/Cardbike3.jpeg"
                        >
                        </v-img>
                        <v-divider></v-divider>
                        <v-card-subtitle class="pb-0 pt-5 text-center">
                          <a
                            href=""
                            class="text-decoration-none black--text text-center subtitle-1"
                          >
                            TVS Raider 125</a
                          >
                          <div class="">
                            <a
                              href=""
                              class="text-decoration-none grey--text title"
                              >RS. 12000</a
                            >
                          </div>
                        </v-card-subtitle>

                        <v-card-text
                          class="text--primary pt-5 CardText text-center"
                        >
                          <div class="d-flex align-items-center justify-center">
                            <div class="section">
                              <h3 class="subtitle-2">Power</h3>
                              <p>225 cc</p>
                            </div>
                            <span class="divider display-5">|</span>
                            <div class="section">
                              <h3 class="subtitle-2">Speed</h3>
                              <p>250 km/h</p>
                            </div>
                            <span class="divider">|</span>
                            <div class="section">
                              <h3 class="subtitle-2">Tank</h3>
                              <p>5 liters</p>
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                      <v-card
                        elevation="0"
                        outlined
                        width="300"
                        class="grey lighten-3 CustomCardShadow ma-4"
                      >
                        <v-img
                          class="white--text align-end"
                          height="200px"
                          src="../../assets/images/cards/Cardbike4.jpeg"
                        >
                        </v-img>
                        <v-divider></v-divider>
                        <v-card-subtitle class="pb-0 pt-5 text-center">
                          <a
                            href=""
                            class="text-decoration-none black--text text-center subtitle-1"
                          >
                            TVS Raider 125</a
                          >
                          <div class="">
                            <a
                              href=""
                              class="text-decoration-none grey--text title"
                              >RS. 12000</a
                            >
                          </div>
                        </v-card-subtitle>

                        <v-card-text
                          class="text--primary pt-5 CardText text-center"
                        >
                          <div class="d-flex align-items-center justify-center">
                            <div class="section">
                              <h3 class="subtitle-2">Power</h3>
                              <p>225 cc</p>
                            </div>
                            <span class="divider display-5">|</span>
                            <div class="section">
                              <h3 class="subtitle-2">Speed</h3>
                              <p>250 km/h</p>
                            </div>
                            <span class="divider">|</span>
                            <div class="section">
                              <h3 class="subtitle-2">Tank</h3>
                              <p>5 liters</p>
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                    </div>
                  </v-carousel-item>
                </v-carousel>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-container>

      <!--***********************************************************Nesw Letter Section***********************************************************-->

      <v-container fluid class="grey lighten-5 py-16 mt-16">
        <v-container class="myrow py-16">
          <v-row>
            <v-col cols="12" class="d-flex flex-nowrap justify-center">
              <div class="text-center">
                <h2 class="NewsHeading black--text">
                  Want product news and updates?
                </h2>
                <p class="NewsText">Sign up for our newsletter.</p>

                <!-- <v-btn-toggle class="mydev" hide-details width="100%">
              <v-text-field
                dense
                hide-details
                class="pa-0"
                placeholder="Search"
                outlined
                height="50px"
                tile
              ></v-text-field>

              <v-btn
                dense
                depressed
                dark
                class="mygreen darken-5 text--white"
                v-model="NewletterEmail"
                >Search</v-btn
              >
            </v-btn-toggle> -->
              </div>
            </v-col>

            <v-col cols="12" class="d-flex justify-center">
              <div class="d-flex float-right mydev">
                <v-text-field
                  tile
                  hide-details
                  class="pa-0"
                  placeholder="Email Address"
                  outlined
                ></v-text-field>

                <v-btn
                  tile
                  depressed
                  dark
                  class="pa-7 mygreen text--white"
                  v-model="NewletterEmail"
                  >submit</v-btn
                >
              </div>
            </v-col>
            <v-col cols="12">
              <div class="d-flex justify-center">
                <div>
                  <v-checkbox
                    depressed
                    text
                    color="green"
                    hide-details
                    class="pa-0"
                  ></v-checkbox>
                </div>
                <div class="mt-5 pa-0">
                  <p small>
                    I agree with the
                    <span class="indigo--text underline--text"
                      >terms and conditions</span
                    >
                  </p>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-container>

      <!--***************************************************************New Images Section******************************************  -->
      <v-container fluid class="grey lighten-5 py-16 mt-16">
        <v-container class="myrow py-16">
          <v-row justify="center">
            <v-col cols="12">
              <v-carousel v-model="MainBike" class="myframe" hide-delimiters>
                <v-carousel-item v-for="(item, i) in videos" :key="i">
                  <div>
                    <iframe
                      width="100%"
                      height="500px"
                      :src="`https://www.youtube.com/embed/${item.src}`"
                      frameborder="0"
                      allowfullscreen
                    ></iframe>
                  </div>
                </v-carousel-item>
              </v-carousel>
              <v-slide-group>
                <v-slide-item
                  v-for="(item, i) in videos"
                  :key="i"
                  v-slot="{ active, toggle }"
                >
                  <v-card
                    :color="active ? 'primary' : 'grey lighten-1'"
                    class="ma-4"
                    width="200"
                    height="100"
                    @click="toggle"
                  >
                    <div>
                      <!-- <v-img
                            :src="item.src"
                            @click="selectImage(i)"
                            width="100%"
                            alt=""
                          ></v-img> -->
                      <v-img
                        :src="`https://img.youtube.com/vi/${item.src}/maxresdefault.jpg`"
                        @click="selectImage(i)"
                        width="100%"
                        alt=""
                      ></v-img>
                    </div>
                  </v-card>
                </v-slide-item>
              </v-slide-group>
            </v-col>
          </v-row>

          <!-- <v-img
              src="https://img.youtube.com/vi/6xWSeoVhMVE/maxresdefault.jpg">

              </v-img> -->
        
        </v-container>
      </v-container>
    </v-main>
  </v-app>
</template>


<script>
// import axios from 'axios';
import circleSlide from "../slider/CircleSlide.vue";
export default {
  components: {
    circleSlide,
  },
  data() {
    return {
      MainBike: 0,
      // images: imageSrc,
      images: [
        {
          src: require("../../assets/images/quality images/bike2.jpg"),
          alt: "Image 1",
        },

        {
          src: require("../../assets/images/quality images/bike9.jpg"),
          alt: "Image 1",
        },
        {
          src: require("../../assets/images/quality images/bike11.jpg"),
          alt: "Image 2",
        },
        {
          src: require("../../assets/images/quality images/bike9.jpg"),
          alt: "Image 3",
        },
        {
          src: require("../../assets/images/quality images/bike5.jpg"),
          alt: "Image 5",
        },
        {
          src: require("../../assets/images/quality images/bike4.jpg"),
          alt: "Image 6",
        },
        {
          src: require("../../assets/images/quality images/bike12.jpg"),
          alt: "Image 5",
        },
        {
          src: require("../../assets/images/quality images/bike11.jpg"),
          alt: "Image 6",
        },
        {
          src: require("../../assets/images/quality images/bike1.jpg"),
          alt: "Image 5",
        },
        {
          src: require("../../assets/images/quality images/bike4.jpg"),
          alt: "Image 6",
        },
        {
          src: require("../../assets/images/quality images/bike5.jpg"),
          alt: "Image 7",
        },

        {
          src: require("../../assets/images/quality images/bike6.jpg"),
          alt: "Image 9",
        },

        {
          src: require("../../assets/images/quality images/bike11.jpg"),
          alt: "Image 12",
        },
        {
          src: require("../../assets/images/quality images/bike12.jpg"),
          alt: "Image 13",
        },
      ],
      NewletterEmail: null,
      toggle_exclusive: 2,
      dropdown_bike: ["bike onw", "bike two", "bike three", "bike four"],
      dropdown_Price: ["1 lack", "2 lack", "3 lack", "4 lack"],
      cardImgSrc: "../../assets/images/cards",
      SearchItem: null,
      activeItem: 0,
      activeInnerItem: 0,
      itemsPerPage: 3,
      sliderGroup: true,
      thumbnails: [],
      imagesArray: [
        {
          title: "Item 2",
          imageSrc: require("../../assets/images/cards/cardBike2.jpeg"),
        },
        {
          title: "Item 3",
          imageSrc: require("../../assets/images/cards/Cardbike4.jpeg"),
        },
        {
          title: "Item 1",
          imageSrc: require("../../assets/images/cards/Cardbike1.jpeg"),
        },
        {
          title: "Item 2",
          imageSrc: require("../../assets/images/cards/cardBike2.jpeg"),
        },
        {
          title: "Item 3",
          imageSrc: require("../../assets/images/cards/Cardbike4.jpeg"),
        },
        {
          title: "Item 4",
          imageSrc: require("../../assets/images/cards/Cardbike5.jpeg"),
        },
        {
          title: "Item 4",
          imageSrc: require("../../assets/images/cards/Cardbike6.jpeg"),
        },
        {
          title: "Item 3",
          imageSrc: require("../../assets/images/cards/Cardbike4.jpeg"),
        },
        {
          title: "Item 1",
          imageSrc: require("../../assets/images/cards/Cardbike1.jpeg"),
        },
        {
          title: "Item 2",
          imageSrc: require("../../assets/images/cards/cardBike2.jpeg"),
        },
        {
          title: "Item 3",
          imageSrc: require("../../assets/images/cards/Cardbike4.jpeg"),
        },
        {
          title: "Item 4",
          imageSrc: require("../../assets/images/cards/Cardbike5.jpeg"),
        },
        {
          title: "Item 4",
          imageSrc: require("../../assets/images/cards/Cardbike6.jpeg"),
        },
        {
          title: "Item 2",
          imageSrc: require("../../assets/images/cards/cardBike2.jpeg"),
        },
        {
          title: "Item 3",
          imageSrc: require("../../assets/images/cards/Cardbike4.jpeg"),
        },
        {
          title: "Item 4",
          imageSrc: require("../../assets/images/cards/Cardbike5.jpeg"),
        },
        {
          title: "Item 4",
          imageSrc: require("../../assets/images/cards/Cardbike6.jpeg"),
        },
      ],

      items: [
        // Array of items, each containing multiple inner items
        [
          {
            title: "Item 1",
            imageSrc: require("../../assets/images/bike1.png"),
          },
          {
            title: "Item 2",
            imageSrc: require("../../assets/images/bike2.png"),
          },
          {
            title: "Item 3",
            imageSrc: require("../../assets/images/bike3.png"),
          },
        ],
        [
          { title: "Item 4", imageSrc: "item4.jpg" },
          { title: "Item 5", imageSrc: "item5.jpg" },
          { title: "Item 6", imageSrc: "item6.jpg" },
          // Add more inner items
        ],
        // Add more items
      ],
      videos: [
        { src: "mikxXFirSPY?t" },
        { src: "mikxXFirSPY?t=6" },
        { src: "7CQVGlbY8H0?t=10" },
        { src: "ytCk9zcOsj4" },
        { src: "mikxXFirSPY?t" },
        { src: "mikxXFirSPY?t=6" },
        { src: "7CQVGlbY8H0?t=10" },
        { src: "ytCk9zcOsj4" },
        { src: "ytCk9zcOsj4" },
        { src: "ytCk9zcOsj4" },
        { src: "mikxXFirSPY?t=6" },
        { src: "7CQVGlbY8H0?t=10" },
        { src: "ytCk9zcOsj4" },
        { src: "mikxXFirSPY?t" },
      ],
      // YTvideos: [
      //   'https://www.youtube.com/watch?v=mikxXFirSPY?t',
      //   'https://www.youtube.com/watch?v=mikxXFirSPY?t=6',
      //   'https://www.youtube.com/watch?v=7CQVGlbY8H0?t=10',
      //   'https://www.youtube.com/watch?v=ytCk9zcOsj4',
      // ],
    };
  },
  methods: {
    // getThumbnails() {
    //   this.YTvideos.forEach(url => {
    //     const videoId = this.getVideoId(url);
    //     const apiUrl = `https://www.googleapis.com/youtube/v3/videos?id=${videoId}&key=YOUR_API_KEY&part=snippet`;

    //     axios.get(apiUrl)
    //       .then(response => {
    //         const videoInfo = response.data.items[0];
    //         const thumbnailUrl = videoInfo.snippet.thumbnails.default.url;
    //         const thumbnailTitle = videoInfo.snippet.title;

    //         this.thumbnails.push({
    //           id: videoId,
    //           url: thumbnailUrl,
    //           title: thumbnailTitle,
    //         });
    //       })
    //       .catch(error => {
    //         console.error('Error retrieving thumbnail:', error);
    //       });
    //   });
    // },
    SearchItems() {
      this.$store.state.SearchItem = this.SearchItem;
      // alert(this.$store.state.SearchItem)
    },
    selectImage(i) {
      this.MainBike = i;
    },
  },
  computed: {
    carouselHeight() {
      // Calculate the height of the carousel based on the aspect ratio and the width
      const width = window.innerWidth;
      const aspectRatio = 16 / 9;
      return `${Math.floor(width / aspectRatio)}px`;
    },
  },
  // mounted() {
  //   this.getThumbnails();
  // },
};
</script>
<style scoped>
.BannerHeading {
  font-size: 32px !important;
  font-family: "Open Sans", sans-serif !important;
}
.BannerHeading3 {
  font-size: 20px !important;
  font-family: "Open Sans", sans-serif !important;
}
</style>
<!-- .myCarItem {
  border-radius:0%  !important;
  background-color: transparent !important;
}
 .v-carousel {
  border-radius:0%  !important;
  background-color: transparent !important;
} -->