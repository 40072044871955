<template>
 
    <v-app id="MainComp" class="mt-0 pt-0">
      <v-main class="mt-0 pt-0">
        <v-container fluid class="myrow pa-0">
          <v-container class="pa-0">
                <!--***********************************************************Top Ads section********************************************************************-->
               
                <v-row class="">
                  <v-col cols="12">
                    <v-sheet height="100" color="grey lighten-3">
                      <span>
                        <img
                          src="../../assets/images/ad.png"
                          width="100%"
                          height="100%"
                          alt="ad"
                        />
                      </span>
                    </v-sheet>
                  </v-col>
                </v-row>
        
                <!--***********************************************************Top img Carousel********************************************************************-->
        
                <v-row class="">
                  <v-col cols="12" md="12" class="pa-3">
                    <div class="text-center BikeName">
                      <h1 class="">SPORTS BIKE | Rs. 9,990</h1>
                    </div>
                  </v-col>
                  <v-col cols="12" md="12" class="d-flex justify-center">
                    <v-carousel
                      class="white carouselImg myCarouselItem"
                      hide-delimiters
                      v-model="MainBike"
                      style="height: auto"
                    >
                      <template v-slot:prev="{ on, attrs }">
                        <v-btn
                          fab
                          icon
                          v-bind="attrs"
                          v-on="on"
                          class="pa-0 white myCarouselNavagationpre"
                        >
                          <v-img
                            class="ml-3 bikesleftIcon"
                            width="1px"
                            src="../../assets/images/specialCards/right-arrow.png"
                          >
                          </v-img>
                        </v-btn>
                      </template>
                      <template v-slot:next="{ on, attrs }">
                        <v-btn
                          fab
                          icon
                          v-bind="attrs"
                          v-on="on"
                          class="pa-0 myCarouselNavagationnext"
                        >
                          <v-img
                            class="mr-3"
                            width="1px"
                            src="../../assets/images/specialCards/right-arrow.png"
                          >
                          </v-img>
                        </v-btn>
                      </template>
                      <v-carousel-item v-for="item in images" :key="item">
                        <div class="pa-3 d-flex justify-center">
                          <div style="width: 50%; height: auto !important">
                            <v-img
                              :src="item.src"
                              @click="selectImage(item)"
                              contain
                              width="100%"
                              class=""
                            ></v-img>
                          </div>
                        </div>
                      </v-carousel-item>
                    </v-carousel>
                  </v-col>
                </v-row>
        
                <!--***********************************************************cards Section********************************************************************-->
               
                <v-row class="px-1 pa-1">
                  <v-col cols="3" class="pa-0">
                    <v-sheet color="white" class="customSheet pa-3">
                      <v-row class="">
                        <v-col cols="3" class="">
                          <v-img src="../../assets/images/specialCards/fast.png">
                          </v-img>
                        </v-col>
                        <v-col cols="9" class="mt-xl-3 text-start">
                          <span class="specialCardText">Top Speed</span>
                          <h3 class="title">225 km/h</h3>
                        </v-col>
                      </v-row>
                    </v-sheet>
                  </v-col>
                  <v-col cols="3" class="pa-0">
                    <v-sheet color="white" class="customSheet pa-3">
                      <v-row class="">
                        <v-col cols="3" class="">
                          <v-img src="../../assets/images/specialCards/fuel.png">
                          </v-img>
                        </v-col>
                        <v-col cols="9" class="mt-xl-3 text-start">
                          <span class="specialCardText">Fuel Capacity</span>
                          <h3 class="title">5.5 Liters</h3>
                        </v-col>
                      </v-row>
                    </v-sheet>
                  </v-col>
                  <v-col cols="3" class="pa-0">
                    <v-sheet color="white" class="customSheet pa-3">
                      <v-row class="">
                        <v-col cols="3" class="">
                          <v-img src="../../assets/images/specialCards/fast.png">
                          </v-img>
                        </v-col>
                        <v-col cols="9" class="mt-xl-3 text-start">
                          <span class="specialCardText">Top Speed</span>
                          <h3 class="title">225 km/h</h3>
                        </v-col>
                      </v-row>
                    </v-sheet>
                  </v-col>
                  <v-col cols="3" class="pa-0">
                    <v-sheet color="white" class="customSheet pa-3">
                      <v-row class=" ">
                        <v-col cols="3" class="">
                          <v-img src="../../assets/images/specialCards/performance.png">
                          </v-img>
                        </v-col>
                        <v-col cols="9" class="mt-xl-3 text-start">
                          <span class="specialCardText"> Performance </span>
                          <h3 class="title">1000cc</h3>
                        </v-col>
                      </v-row>
                    </v-sheet>
                  </v-col>
                  <v-col cols="3" class="pa-0">
                    <v-sheet color="white" class="customSheet pa-3">
                      <v-row class="">
                        <v-col cols="3" class="">
                          <v-img src="../../assets/images/specialCards/bike.png">
                          </v-img>
                        </v-col>
                        <v-col cols="9" class="mt-xl-3 text-start">
                          <span class="specialCardText">POWER</span>
                          <h3 class="title">125 Nm</h3>
                        </v-col>
                      </v-row>
                    </v-sheet>
                  </v-col>
                  <v-col cols="3" class="pa-0">
                    <v-sheet color="white" class="customSheet pa-3">
                      <v-row class="">
                        <v-col cols="3" class="">
                          <v-img src="../../assets/images/specialCards/fuel.png">
                          </v-img>
                        </v-col>
                        <v-col cols="9" class="mt-xl-3 text-start">
                          <span class="specialCardText">Fuel Capacity</span>
                          <h3 class="title">5.5 Liters</h3>
                        </v-col>
                      </v-row>
                    </v-sheet>
                  </v-col>
                  <v-col cols="3" class="pa-0">
                    <v-sheet color="white" class="customSheet pa-3">
                      <v-row class="">
                        <v-col cols="3" class="">
                          <v-img src="../../assets/images/specialCards/bike.png">
                          </v-img>
                        </v-col>
                        <v-col cols="9" class="mt-xl-3 text-start">
                          <span class="specialCardText">POWER</span>
                          <h3 class="title">125 Nm</h3>
                        </v-col>
                      </v-row>
                    </v-sheet>
                  </v-col>
                  <v-col cols="3" class="pa-0">
                    <v-sheet color="white" class="customSheet pa-3">
                      <v-row class="">
                        <v-col cols="3" class="">
                          <v-img src="../../assets/images/specialCards/fuel.png">
                          </v-img>
                        </v-col>
                        <v-col cols="9" class="mt-xl-3 text-start">
                          <span class="specialCardText">Fuel Capacity</span>
                          <h3 class="title">5.5 Liters</h3>
                        </v-col>
                      </v-row>
                    </v-sheet>
                  </v-col>
                </v-row>
        
                <!--***********************************************************videos Carousel*********************************************************************-->
               
                <v-row class="">
                  <v-col cols="12" md="12" class="px-1">
                    <v-slide-group
                      show-arrows="false"
                      class="d-flex justify-center mySlider pa-0 ma-0"
                    >
                      <template v-slot:prev="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          depressed
                          class="black--text sliderBtns slider-left-btn"
                          color="rgba(27, 40, 58, 0.6)"
                          elevation="2"
                        >
                          <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                      </template>
                      <template v-slot:next="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          icon
                          elevation="2"
                          depressed
                          class="black--text sliderBtns slider-right-btn"
                          color="rgba(27, 40, 58, 0.6)"
                        >
                          <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                      </template>

                      <v-slide-item
                        v-for="item in videos"
                        :key="item"
                        v-slot="{ active }"
                      >
                        <v-card
                          :color="active ? 'primary' : 'grey lighten-1'"
                          class="ma-2"
                          width="359px"
                          height="202px"
                          target="_blank"
                        >
                          <div>
                            <iframe
                              :src="`https://www.youtube.com/embed/${item.src}`"
                              frameborder="0"
                              allowfullscreen
                              target="_blank"
                              width="359px"
                              height="202px"
                            ></iframe>
                          </div>
                        </v-card>
                      </v-slide-item>
                    </v-slide-group>
                  </v-col>
                </v-row>
        
                <!--******************************************************************Expension Panels******************************************************************-->
        
                <v-row class="mt-md-10">
                  <v-col cols="10">
                    <template>
                      <v-expansion-panels
                        flat
                        class=""
                        elevation="0"
                        multiple
                        v-model="panel"
                      >
                        <!--********************************************************************01 Panel********************************************************************-->
                        <v-expansion-panel flat class="customHeader">
                          <v-expansion-panel-header
                            elevation="0"
                            :disable-icon-rotate="false"
                            class="grey lighten-4 pa-3 pa-0"
                          >
                            <div>
                              <span class="text-left BoldFont success--text">
                                Engine</span
                              >
                            </div>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <v-simple-table class="specificationTable">
                              <template v-slot:default>
                                <tbody>
                                  <tr v-for="item in engineArray" :key="item.name">
                                    <td width="50%" class="BoldFont">
                                      {{ item.name }}
                                    </td>
                                    <td width="50%" class="ThinFont">
                                      {{ item.value }}
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
        
                        <!--  ******************************************************************Two Panel****************************************************************** -->
                        <v-expansion-panel flat dark class="customHeader">
                          <v-expansion-panel-header
                            elevation="0"
                            :disable-icon-rotate="false"
                            class="grey lighten-4 pa-3"
                          >
                            <div>
                              <span class="text-left BoldFont success--text">
                                CHASSIS
                              </span>
                            </div>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content class="pa-0">
                            <v-simple-table class="specificationTable">
                              <template v-slot:default>
                                <tbody>
                                  <tr v-for="item in ChasesArray" :key="item.name">
                                    <td width="50%" class="BoldFont">
                                      {{ item.name }}
                                    </td>
                                    <td width="50%" class="ThinFont">
                                      {{ item.value }}
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
        
                        <!--*********************************************************************03 Panel********************************************************************-->
                        <v-expansion-panel flat dark class="customHeader">
                          <v-expansion-panel-header
                            elevation="0"
                            :disable-icon-rotate="false"
                            class="grey lighten-4 pa-3"
                          >
                            <div>
                              <span class="text-left BoldFont success--text">
                                Dimensions
                              </span>
                            </div>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content class="pa-0">
                            <v-simple-table class="specificationTable">
                              <template v-slot:default>
                                <tbody>
                                  <tr v-for="item in DChessesArray" :key="item.name">
                                    <td width="50%" class="BoldFont">
                                      {{ item.name }}
                                    </td>
                                    <td width="50%" class="ThinFont">
                                      {{ item.value }}
                                    </td>
                                  </tr>
                                </tbody>
                              </template>
                            </v-simple-table>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </template>
                  </v-col>
        
                  <!--******************************************************************Ads Sidebar******************************************************************-->
                  <v-col
                    cols="12"
                    md="2"
                    class="d-flex flex-column justify-space-between"
                  >
                    <v-card class="">
                      <v-card-title class="advertisement-title"
                        >Advertisement</v-card-title
                      >
                      <v-card-text>
                        <v-img
                          src="../../assets/images/product_4.jpg"
                          width="100%"
                          height="130"
                        >
                        </v-img>
                      </v-card-text>
                      <v-card-caption class="text-center">
                        <v-btn text class="red--text"> shop Now </v-btn>
                      </v-card-caption>
                    </v-card>
                    <v-card class="">
                      <v-card-title class="advertisement-title"
                        >Advertisement</v-card-title
                      >
                      <v-card-text> </v-card-text>
                      <v-img
                        src="../../assets/images/product_1.jpg"
                        width="100%"
                        height="130"
                      >
                      </v-img>
                      <v-card-caption class="text-center">
                        <v-btn text class="red--text"> shop Now </v-btn>
                      </v-card-caption>
                    </v-card>
                  </v-col>
                </v-row>

            </v-container>

        </v-container>
      </v-main>
    </v-app>
 
</template>
  
  <script>
export default {
  data() {
    return {
      panel: [0],
      //******************************** Arrays for the expension panels ***********************************************
      powerArray: [
        {
          name: "Displacement",
          value: 124,
        },
        {
          name: "Max Power",
          value: 124,
        },

        {
          name: "Max Torque",
          value: 124,
        },
        {
          name: "Mileage - ARAI",
          value: 124,
        },
      ],
      BWSArray: [
        {
          name: "Mileage - ARAI",
          value: "Telescopic",
        },
        {
          name: "Rear Suspension",
          value: "Swing Arm",
        },

        {
          name: "Braking System",
          value: "CBS",
        },
        {
          name: "Front Brake Type",
          value: "Drum",
        },
      ],
      DChessesArray: [
        {
          name: "FRAME",
          value: "Arch bar truck",
        },
        {
          name: "HEIGHT EXCLUDING MIRRORS",
          value: "1070mm",
        },

        {
          name: "PERMITTED TOTAL WEIGHT",
          value: "185kg",
        },
        {
          name: "GROUND CLEARANCE",
          value: "170mm",
        },
        {
          name: "LENGTH",
          value: "2030mm",
        },
        {
          name: "WHEELBASE",
          value: "1345mm",
        },
        {
          name: "USABLE TANK VOLUME",
          value: "10L",
        },
        {
          name: "UNLADEN WEIGHT",
          value: "147kg",
        },
        {
          name: "WIDTH EXCLUDING MIRRORS",
          value: "780mm",
        },
        {
          name: "SEAT HEIGHT",
          value: "810mm",
        },
      ],
      //***********************************************Array for Engine Table***********************************************
      engineArray: [
        {
          name: "displacement",
          value: "175.3",
        },
        {
          name: "starting",
          value: "Electric",
        },
        {
          name: "ignition",
          value: "TLI",
        },
        {
          name: "final Drive",
          value: "Chain",
        },
        {
          name: "Gear Shifting type",
          value: "6-speed",
        },
        {
          name: "engine mode",
          value: "1-cylinder/4-stroke/4-valve",
        },
        {
          name: "bore x stroke",
          value: "64x54.4mm",
        },
        {
          name: "compressioni ratio",
          value: "11.1",
        },
        {
          name: "rated output",
          value: "13.3KW/9500r/min",
        },
        {
          name: "max. torque",
          value: "14N.m/7000r/min",
        },
        {
          name: "fuel supply",
          value: "EFI",
        },
        {
          name: "LUBRICATION",
          value: "Pressure splash lubrication",
        },
        {
          name: "blutch",
          value: "Wet multi-plate",
        },
        {
          name: "colling system",
          value: "Liquid-cooled",
        },
        {
          name: "value train",
          value: "SOHC",
        },
      ],

      //***********************************************Array for Chases Table***********************************************
      ChasesArray: [
        {
          name: "front suspension",
          value: "Telescopic forks",
        },
        {
          name: "front suspension travel",
          value: "110mm",
        },
        {
          name: "rear suspension",
          value: "Swingarm",
        },
        {
          name: "rear suspension travel",
          value: "50mm",
        },
        {
          name: "front brake",
          value: "Disc",
        },
        {
          name: "rear brake",
          value: "Disc",
        },
        {
          name: "rear type",
          value: "160/70-17",
        },
        {
          name: "front type",
          value: "100/80-17",
        },
      ],

      sliderValue: 0,
      MainBike: 0,

      //***********************************************Top img Carousel Arrays***********************************************
      Currentbike: require("../../assets/images/quality images/bike1.png"),
      images: [
        {
          src: require("../../assets/images/quality images/bike2.jpg"),
          alt: "Image 1",
        },

        {
          src: require("../../assets/images/quality images/bike9.jpg"),
          alt: "Image 1",
        },
        {
          src: require("../../assets/images/quality images/bike11.jpg"),
          alt: "Image 2",
        },
        {
          src: require("../../assets/images/quality images/bike9.jpg"),
          alt: "Image 3",
        },
        {
          src: require("../../assets/images/quality images/bike5.jpg"),
          alt: "Image 5",
        },
        {
          src: require("../../assets/images/quality images/bike4.jpg"),
          alt: "Image 6",
        },
        {
          src: require("../../assets/images/quality images/bike12.jpg"),
          alt: "Image 5",
        },
        {
          src: require("../../assets/images/quality images/bike11.jpg"),
          alt: "Image 6",
        },
        {
          src: require("../../assets/images/quality images/bike1.jpg"),
          alt: "Image 5",
        },
        {
          src: require("../../assets/images/quality images/bike4.jpg"),
          alt: "Image 6",
        },
        {
          src: require("../../assets/images/quality images/bike5.jpg"),
          alt: "Image 7",
        },

        {
          src: require("../../assets/images/quality images/bike6.jpg"),
          alt: "Image 9",
        },

        {
          src: require("../../assets/images/quality images/bike11.jpg"),
          alt: "Image 12",
        },
        {
          src: require("../../assets/images/quality images/bike12.jpg"),
          alt: "Image 13",
        },
      ],
      images_2: [
        {
          src: require("../../assets/images/bikes/1.jpeg"),
          alt: "Image 1",
        },

        {
          src: require("../../assets/images/bikes/2.jpeg"),
          alt: "Image 1",
        },
        {
          src: require("../../assets/images/bikes/3.jpeg"),
          alt: "Image 2",
        },
        {
          src: require("../../assets/images/bikes/4.jpeg"),
          alt: "Image 3",
        },
        {
          src: require("../../assets/images/bikes/5.jpeg"),
          alt: "Image 5",
        },
        {
          src: require("../../assets/images/bikes/6.jpeg"),
          alt: "Image 6",
        },
        {
          src: require("../../assets/images/bikes/7.jpeg"),
          alt: "Image 5",
        },
        {
          src: require("../../assets/images/bikes/8.jpeg"),
          alt: "Image 6",
        },
        {
          src: require("../../assets/images/bikes/9.jpeg"),
          alt: "Image 5",
        },
        {
          src: require("../../assets/images/bikes/5.jpeg"),
          alt: "Image 6",
        },
        {
          src: require("../../assets/images/bikes/3.jpeg"),
          alt: "Image 7",
        },

        {
          src: require("../../assets/images/bikes/9.jpeg"),
          alt: "Image 9",
        },

        {
          src: require("../../assets/images/bikes/4.jpeg"),
          alt: "Image 12",
        },
        {
          src: require("../../assets/images/bikes/3.jpeg"),
          alt: "Image 13",
        },
      ],

      //***********************************************youtube Videos URl***********************************************
      videos: [
        { src: "mikxXFirSPY?t" },
        { src: "mikxXFirSPY?t=6" },
        { src: "7CQVGlbY8H0?t=10" },
        { src: "ytCk9zcOsj4" },
        { src: "mikxXFirSPY?t" },
        { src: "mikxXFirSPY?t=6" },
        { src: "7CQVGlbY8H0?t=10" },
        { src: "ytCk9zcOsj4" },
        { src: "ytCk9zcOsj4" },
        { src: "ytCk9zcOsj4" },
        { src: "ytCk9zcOsj4" },
        { src: "ytCk9zcOsj4" },
        { src: "ytCk9zcOsj4" },
        { src: "ytCk9zcOsj4" },
        { src: "ytCk9zcOsj4" },
        { src: "ytCk9zcOsj4" },
        { src: "ytCk9zcOsj4" },
        { src: "ytCk9zcOsj4" },
        { src: "ytCk9zcOsj4" },
        { src: "ytCk9zcOsj4" },
        { src: "ytCk9zcOsj4" },
        { src: "ytCk9zcOsj4" },
      ],

      dialog: false,
      dialogIndex: 0,
      imgSrc: "../../assets/images/bike3.png",
      alt: "empty",
    };
  },
  methods: {
    bikeDialouge(Currentbike) {
      this.dialog = true;
      this.dialogIndex = Currentbike;
    },
    selectImage(i) {
      this.MainBike = i;
    },
    getVideoEmbedUrl(videoId) {
      return `https://www.youtube.com/embed/${videoId}`;
    },
  },

  computed: {
    currentVideo() {
      return this.videos[this.sliderValue];
    },
  },
};
</script>
  

<style scoped>
.specificationTable tbody tr:hover {
  background-color: transparent !important;
}
.specificationTable tbody tr {
  height: 60px !important;
}
.custom-table3 tbody tr:hover,
.custom-table2 tbody tr:hover {
  background-color: transparent !important;
}
.custom-table3 {
  border: 0.2px solid rgb(238, 236, 236) !important;
}
.custom-table3 tbody data-v-2ded9186 {
  border-bottom: none !important;
}
.mySlider {
  width: min-content !important;
  position: relative !important;
}
.sliderBtns {
  background-color: #329ef4 !important;
  color: #fff !important;
  z-index: 4 !important;
}
.sliderBtns2 {
  background-color: black !important;
  color: #fff !important;
}
.BikesSlider > .v-slide-group__prev,
.BikesSlider > .v-slide-group__next {
  color: red !important;
}
.BikesSlider > .bikesCustomBtn {
  width: 10px !important;
  height: 90% !important;
  color: red;
}

.customHeader {
  margin: 0% !important;
}
.customSlideBtn > .v-slide-group__prev--disabled {
  width: 20% !important;
  border: 1px solid red !important;
}

.slider-left-btn {
  position: absolute;
  top: 50% !important;
  transform: translateY(-50%);
  left: 1%;
}
.slider-right-btn {
  position: absolute;
  top: 50% !important;
  transform: translateY(-50%);
  right: 1%;
}
.specialCardText {
  font-size: 12px !important;
  color: rgb(156, 156, 156) !important;
  text-transform: capitalize !important;
}
.slide-group__prev--disabled {
  display: none !important;
}

.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:last-child,
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border-bottom: none !important;
}
</style>

   


